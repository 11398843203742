// import css
import '~/css/app.pcss'

// import icons
import '~/js/assets/icons.js'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// splidei
import '@splidejs/splide/css/core'
import Splide from '@splidejs/splide'
window.Splide = Splide

// // alpine
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
const navigation = () => ({
    atTop: true,
    navAtTop: true,
    activeSubNav: '',
    showMobileNavigation: false,
    debounce(func, wait) {
        let later = function() {
            this.timeout = null
            func.apply(this, [])
        }
        clearTimeout(this.timeout)
        this.timeout = setTimeout(later, wait)
    },
    scroll() {
        const scrollPosition = window.scrollY
        const checkAnimation = scrollPosition < 114

        if (checkAnimation != this.atTop) {
            this.atTop = checkAnimation
        }
    }
})
Alpine.data('navigation', navigation)
Alpine.plugin(focus)
Alpine.plugin(collapse)
Alpine.start()

// Main app
const main = async () => {
    const pinia = createPinia()
    pinia.use(piniaPluginPersistedstate)

    // update the window variables
    fetch(`/actions/users/session-info`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        },
    })
        .then(response => response.json())
        .then(session => {
            window.hasCurrentUser = !session.isGuest
            window.csrf.name = session.csrfTokenName
            window.csrf.value = session.csrfTokenValue
    
            if (!session.isGuest && session.id) {
                window.userId = session.id
            }
        })
        .catch(error => {
            console.error('Error fetching session info:', error);
        })
    

    window.pinia = pinia
}

// Execute async function
main().then(() => {
    console.log()
})

// // Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR active')
    })
}