// importing and setting up Font Awesome
import { dom, library } from '@fortawesome/fontawesome-svg-core';
import { all } from '@awesome.me/kit-3744b09d00/icons'

// convert icons for Icon.php file
// const iconList = all.map(icon => `'${icon.prefix} fa-${icon.iconName}' => '${icon.iconName.charAt(0).toUpperCase() + icon.iconName.replace('-', ' ').slice(1)} (${icon.prefix})'`)
// console.log(iconList.filter((item, index) => iconList.indexOf(item) === index))

library.add(...all)

// convert i tags to SVG
dom.watch({
    autoReplaceSvgRoot: document,
    observeMutationsRoot: document.body
})
